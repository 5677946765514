import React, { Component } from 'react'
import Link from '../utils/link'

class Footer extends Component {

	render() {
    const { options } = this.props.data.wordpressAcfOptions

		return (

			<footer className='footer'>
				<div className='footer__inner'>
          <Link to='/' className='footer__logo'><img src={require('../assets/images/logo.svg')} alt='Matthew Pringle' /></Link>
          <p>Your Strategic,<br/> Commercial Advisor</p>
          <ul>
            { options.email_address && <li><Link to={`mailto:${options.email_address}`}>Email Matthew</Link></li> }
            { options.phone_number && <li><Link to={`tel:${options.phone_number}`}>{options.phone_number}</Link></li> }
            { options.resume && <li><Link to={options.resume.source_url}>Resumé</Link></li> }
            { options.linkedin && <li><Link to={options.linkedin}>LinkedIn</Link></li> }
          </ul>
				</div>
			</footer>

		)

	}

}

export default Footer
